
<template>
    <div>
        
        <WhiteCard>
            <h1>h1</h1>
            <h2 class="pink">h2.pink</h2>
            <h3 class="gray">h3.gray</h3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
        </WhiteCard>

        <div class="container gray-bg">
            <form action="">
                <h3>Prova form</h3>
                <div class="campo">
                    <label class="label" for="test">label</label>
                    <input name="test" type="text" placeholder="placeholder">
                </div>
                <div class="campo">
                    <label class="label" for="email">email</label>
                    <input name="email" type="email" placeholder="email">
                </div>
                <div class="campo">
                    <label class="label" for="area">textarea</label>
                    <textarea name="area" id="" rows="5" placeholder="write here"></textarea>
                </div>
                <button class="btn"><span>SEND</span></button>
            </form>
        </div>
        
        <br>

        <h3 class="label">prova titolo label</h3>
        <p class="label">prova paragraph label</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque cumque consequuntur corporis molestiae commodi. Doloremque aut nam ipsa maxime tenetur. Neque impedit voluptates optio quaerat tempore maiores laboriosam explicabo dolor.</p>

        <p> <a href="#">https://www.qtedu.com</a> </p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odit aliquam quis fugiat corporis vero architecto rerum at eos harum, sequi suscipit cumque provident. Labore iusto officia nisi? In, sunt sequi.</p>
        <p><span>Lorem ipsum dolor sit</span> amet consectetur adipisicing elit. Possimus neque, <span>provident</span> qui commodi tempore modi ad atque sit soluta rem, error dignissimos deleniti <span>accusantium. Delectus officia omnis accusantium odit</span> repudiandae?</p>

        <h2 class="gray">esempio lista</h2> 
        <h3 class="pink">.list-1</h3>
        <ul class="list-1">
            <li>
                <p>punto 1</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat rem alias minus nemo qui! Omnis similique dolor labore dicta saepe praesentium, eligendi vitae temporibus voluptatum officia fugit incidunt iste nesciunt!</p>
            </li>
            <li>
                <h3>punto 2</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quia dolore sapiente pariatur dignissimos impedit minima! Voluptatum magni sapiente accusantium ipsum harum, nihil necessitatibus fugit voluptate inventore in consequuntur quos!</p>
            </li>
            <li>
                punto 3
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus qui labore sunt esse eum nisi quae quis facilis facere saepe! Provident totam dicta laboriosam tempore eveniet? Dicta architecto quod nulla?</p>
            </li>
            <li>
                <h2>punto 4</h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis ullam nemo odit, est quia aliquam cumque sequi fugit blanditiis. Provident iusto, nemo dolor asperiores repellendus praesentium ducimus doloremque quidem eligendi.
            </li>
        </ul>

        <h3 class="pink">.list-2</h3>
        <ul class="list-2">
            <li>
                punto 1
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat rem alias minus nemo qui! Omnis similique dolor labore dicta saepe praesentium, eligendi vitae temporibus voluptatum officia fugit incidunt iste nesciunt!</p>
            </li>
            <li>
                <h3>punto 2</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum quia dolore sapiente pariatur dignissimos impedit minima! Voluptatum magni sapiente accusantium ipsum harum, nihil necessitatibus fugit voluptate inventore in consequuntur quos!</p>
            </li>
            <li>
                punto 3
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus qui labore sunt esse eum nisi quae quis facilis facere saepe! Provident totam dicta laboriosam tempore eveniet? Dicta architecto quod nulla?</p>
            </li>
            <li>
                <h2>punto 4</h2>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis ullam nemo odit, est quia aliquam cumque sequi fugit blanditiis. Provident iusto, nemo dolor asperiores repellendus praesentium ducimus doloremque quidem eligendi.
            </li>
        </ul>

        <h2 class="gray">buttons and links</h2>

        <button class="btn"><span>button.btn</span></button>
        <button class="btn light"><span>button.btn.light</span></button>
        <button class="btn border"><span>button.btn.border</span></button>
        <button class="btn border slim"><span class="icon-left">btn.border.slim > span.icon-left</span></button>
        <button class="btn light slim"><span class="icon-right">btn.light.slim > span.icon-right</span></button>
        <a href="#" class="btn"><span>a.btn</span></a>
        <a href="#" class="btn slim light"><span>a.btn.light</span></a>
        <a href="#" class="btn border"><span class="icon-right">a.btn.border > span.icon-right</span></a>
        <a href="#" class="btn border"><span class="icon-left">a.btn.border > span.icon-left</span></a>
        <a href="#" class="btn"><span class="icon-right">a.btn.border > span.icon-right</span></a>
        <a href="#" class="btn"><span class="icon-left">a.btn.border > span.icon-left</span></a>



    </div>
</template>

<script>
import WhiteCard from '../components/atomic-elements/WhiteCard.vue'
export default {
    name: 'typography',
    components: {
        WhiteCard
    },
    data: () => {
        return {}
    },
    methods: {},
    computed: {},
    mounted() {}
}
</script>

<style lang="scss" scoped>
</style>